import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
countries.registerLocale(enLocale);

export const POINT_TYPE = [
    { label: "pick", value: "location" },
    { label: "drop", value: "destination" }
]

export const OWNER_TYPES = [
    { label: "Company", value: "CompanyModel" },
    { label: "User", value: "UserModel" },
    { label: "Fleet", value: "FleetModel" },
]

export const PACKAGING_TYPE = [
    { label: "unit", value: "unit" },
    { label: "pallet", value: "pallet"},
    { label: "box", value: "box"},
    { label: "crate", value: "crate"},
    { label: "sack", value: "sack"},
    { label: "drum", value: "drum"},
    { label: "container", value: "container"}
]

export const AVAILABILITY_OPTIONS =[
    { label: "online", value: "online" },
    { label: "busy", value: "busy" },
    { label: "offline", value: "offline" },
]

export const SUPPORT_CATEGORIES =[
    { label: "Late driver", value: "lateDriver" },
    { label: "Broken goods", value: "brokenGoods" },
    { label: "Payment Issue", value: "paymentIssue" },
    { label: "Other", value: "other" }
]

export const PAYMENT_METHODS = [
    { label: "Visa", value: "visa" },
    { label: "MasterCard", value: "mastercard" },
    { label: "Bank Transfer", value: "bank" },
    { label: "Ecocash", value: "ecocash" },
    { label: "OneMoney", value: "onemoney" },
    { label: "Telecash", value: "telecash" },
    { label: "Cash", value: "cash" },
    { label: "Wallet", value: "wallet" }
]

export const SUPPORT_STATUSES = [
    { label: "Open", value: "open" },
    { label: "In progress", value: "inprogress" },
    { label: "Closed", value: "closed" }
]

export const ENQUIRY_STATUSES = [
    { label: "Pending", value: "pending" },
    { label: "In progress", value: "inprogress" },
    { label: "Complete", value: "complete" }   
]

export const ENQUIRY_CATEGORIES = [
    { label: "Driver", value: "driver" },
    { label: "Partner", value: "partner" },
    { label: "General", value: "general" }   
]

export const COUNTRY_CODES = [
    { label: '+263 (Zimbabwe)', value: '+263 (Zimbabwe)' },
    { label: '+1 (USA)', value: '+1 (USA)' },
    { label: '+44 (UK)', value: '+44 (UK)' },
    { label: '+86 (China)', value: '+86 (China)' },
    { label: '+91 (India)', value: '+91 (India)' },
    { label: '+27 (South Africa)', value: '+27 (South Africa)' },
];

export const CURRENCIES = [
    { label: 'ZWL', value: 'ZWL' },
    { label: 'ZAR', value: 'ZAR' },
    { label: 'USD', value: 'USD' },
    { label: 'GBP', value: 'GBP' },
    { label: 'EUR', value: 'EUR' },
    { label: 'AUD', value: 'AUD' },
    { label: 'CAD', value: 'CAD' },
    { label: 'JPY', value: 'JPY' },
    { label: 'CNY', value: 'CNY' },
    { label: 'INR', value: 'INR' },
    { label: 'RUB', value: 'RUB' },
    { label: 'NGN', value: 'NGN' },
    { label: 'BRL', value: 'BRL' },
    { label: 'MXN', value: 'MXN' },
    { label: 'KRW', value: 'KRW' },
    { label: 'IDR', value: 'IDR' },
    { label: 'TRY', value: 'TRY' },
    { label: 'SGD', value: 'SGD' },
    { label: 'PLN', value: 'PLN' },
    { label: 'CHF', value: 'CHF' },
    { label: 'THB', value: 'THB' },
    { label: 'MYR', value: 'MYR' },
    { label: 'NZD', value: 'NZD' },
    { label: 'NOK', value: 'NOK' },
    { label: 'SEK', value: 'SEK' },
    { label: 'DKK', value: 'DKK' },
    { label: 'CZK', value: 'CZK' },
    { label: 'HUF', value: 'HUF' },
    { label: 'ILS', value: 'ILS' },
    { label: 'CLP', value: 'CLP' },
    { label: 'PHP', value: 'PHP' },
    { label: 'AED', value: 'AED' },
    { label: 'SAR', value: 'SAR' },
    { label: 'HKD', value: 'HKD' },
    { label: 'PKR', value: 'PKR' },
    { label: 'KWD', value: 'KWD' },
    { label: 'QAR', value: 'QAR' },
    { label: 'EGP', value: 'EGP' },
    { label: 'VND', value: 'VND' },
    { label: 'UAH', value: 'UAH' },
    { label: 'COP', value: 'COP' },
    { label: 'ARS', value: 'ARS' },
    { label: 'ZAR', value: 'ZAR' },
    { label: 'UGX', value: 'UGX' },
    { label: 'KES', value: 'KES' },
    { label: 'GHS', value: 'GHS' },
    { label: 'TZS', value: 'TZS' },
    { label: 'RWF', value: 'RWF' },
    { label: 'MAD', value: 'MAD' },
    { label: 'AOA', value: 'AOA' },
    { label: 'XAF', value: 'XAF' },
    { label: 'XOF', value: 'XOF' },
    { label: 'ZMW', value: 'ZMW' },
    { label: 'MZN', value: 'MZN' },
    { label: 'SCR', value: 'SCR' },
    { label: 'MUR', value: 'MUR' },
    { label: 'BWP', value: 'BWP' },
    { label: 'NAD', value: 'NAD' },
    { label: 'SZL', value: 'SZL' },
    { label: 'LSL', value: 'LSL' },
    { label: 'BIF', value: 'BIF' },
    { label: 'CDF', value: 'CDF' },
    { label: 'DJF', value: 'DJF' },
    { label: 'GMD', value: 'GMD' },
    { label: 'GNF', value: 'GNF' },
    { label: 'KES', value: 'KES' },
    { label: 'LRD', value: 'LRD' },
    { label: 'LYD', value: 'LYD' },
    { label: 'MGA', value: 'MGA' },
    { label: 'MWK', value: 'MWK' },
    { label: 'NOK', value: 'NOK' },
    { label: 'RWF', value: 'RWF' },
    { label: 'SHP', value: 'SHP' },
    { label: 'SLL', value: 'SLL' },
    { label: 'SOS', value: 'SOS' },
    { label: 'SSP', value: 'SSP' },
    { label: 'STD', value: 'STD' },
    { label: 'SZL', value: 'SZL' },
    { label: 'TND', value: 'TND' },
    { label: 'TOP', value: 'TOP' },
    { label: 'TZS', value: 'TZS' },
    { label: 'UGX', value: 'UGX' },
    { label: 'USD', value: 'USD' },
    { label: 'VUV', value: 'VUV' },
    { label: 'WST', value: 'WST' },
    { label: 'XAF', value: 'XAF' },
    { label: 'XCD', value: 'XCD' },
    { label: 'XOF', value: 'XOF' },
    { label: 'XPF', value: 'XPF' },
    { label: 'YER', value: 'YER' },
    { label: 'ZAR', value: 'ZAR' },
    { label: 'ZMW', value: 'ZMW' },
]

export const ZONES = [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
    { label: "E", value: "E" },
    { label: "F", value: "F" },
    { label: "G", value: "G" },
    { label: "H", value: "H" },
    { label: "I", value: "I" },
    { label: "J", value: "J" },
    { label: "K", value: "K" },
    { label: "L", value: "L" },
    { label: "M", value: "M" },
    { label: "N", value: "N" },
    { label: "O", value: "O" },
    { label: "P", value: "P" },
]

export const COLOR_CODES = {
    company:{
        status:{
            active: "bg-green-100 text-green-800",
            inactive: "bg-gray-100 text-gray-800"
        }
    },
    fleet:{
        status:{
            active: "bg-green-100 text-green-800",
            inactive: "bg-gray-100 text-gray-800"
        }
    },
    trip:{
        status:{
            pending: "bg-yellow-100 text-yellow-800",
            scheduled: "bg-blue-100 text-blue-800",
            accepted: "bg-green-100 text-green-800",
            onroute: "bg-purple-100 text-purple-800",
            complete: "bg-gray-100 text-gray-800",
            cancelled: "bg-red-100 text-red-800",
        }
    },
    trailer:{
        availability:{
            online: "bg-green-100 text-green-800",
            offline: "bg-gray-100 text-gray-800",
            busy: "bg-yellow-100 text-yellow-800"
        }
    },
    user:{
        status:{
            incomplete: "bg-yellow-100 text-yellow-800",
            complete: "bg-green-100 text-green-800",
            inactive: "bg-gray-100 text-gray-800",
            active: "bg-blue-100 text-blue-800",
            banned: "bg-red-100 text-red-800"
        }
    },
    booking:{
        status:{
            pending: "bg-yellow-100 text-yellow-800",
            scheduled: "bg-blue-100 text-blue-800",
            accepted: "bg-green-100 text-green-800",
            onroute: "bg-purple-100 text-purple-800",
            complete: "bg-gray-100 text-gray-800",
            cancelled: "bg-gray-100 text-gray-800",
        }
    },
    point:{
        status:{
            pending: "bg-yellow-100 text-yellow-800",
            arrived: "bg-blue-100 text-blue-800",
            loading: "bg-green-100 text-green-800",
            offloading: "bg-green-100 text-green-800",
            onroute: "bg-purple-100 text-purple-800",
            complete: "bg-gray-100 text-gray-800"
        }
    },
    vehicle:{
        status:{
            active: "bg-green-100 text-green-800",
            inactive: "bg-gray-100 text-gray-800"
        }
    },
    enquiry:{
        status:{
            pending: "bg-yellow-100 text-yellow-800",
            inprogress: "bg-gray-100 text-gray-800",
            closed: "bg-gray-100 text-gray-800"
        }
    },
}

export const generateLongUniqueId = () => {
    return (
      Math.random().toString(36).substring(2) +
      Math.random().toString(36).substring(2) +
      Math.random().toString(36).substring(2)
    );
}

export const camelToHuman = (str) => {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());
}

// Utility function to get country code
export const getCountryCode = (countryName) => {
  return countries.getAlpha2Code(countryName.trim(), "en") || null;
};