import axios from 'axios';
import { getCountryCode } from '../util/general.util';

const axiosWithoutInterceptors = axios.create();

export const binLookup = async (number) => {
    try {
        const response = await axiosWithoutInterceptors.get(`https://api.apilayer.com/bincheck/${number}`, {
            headers: {
                'apikey': process.env.REACT_APP_APILAYER_API_KEY,
                'Content-Type': 'application/json'
            }
        })
        
        return {
            ...response.data,
            countryCode: getCountryCode(response?.data?.country)
        };
    } catch (error) {
        console.error(error);
        throw error;
    }
};