import * as StationPermissions from '../modules/StationPermissions'

import {Link, useParams} from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import axios from 'axios'
import context from '../../../context'

const ViewStation = ({
    role
}) =>{

    const {id} = useParams();
    const [station, setStation] = useState(null)

    const fetchStation = () => {
        axios.get(`${context.SERVER_URL}/api/stations/${id}`)
        .then((response)=>{

            const _station = response.data;
            setStation(_station);
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    useEffect(()=>{
        fetchStation()
    },[])

    return(
        <div className='p-3'>
            <div className='flex flex-row'>
                <Link 
                    to = {"/stations"}
                    className = "underline pr-2"
                >stations</Link> | 
                {
                    StationPermissions.isAuthorized("edit", role) &&
                    <Link 
                        to = {`/stations/edit/${id}`}
                        className = "underline pl-2"
                    >edit</Link>
                }
            </div>
            <h1 className='text-primary-2 text-lg font-bold'>View Station</h1>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Name:</label>
                <div className='text-gray-500 font-bold'>{station?.name}</div>
            </div>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Address:</label>
                <div className='text-gray-500 font-bold'>{station?.zone}</div>
            </div>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Country:</label>
                <div className='text-gray-500 font-bold'>{station?.country}</div>
            </div>
            <div className='flex flex-row'>
                <label className='text-primary-2 font-bold mr-2'>Status:</label>
                <div className='text-gray-500 font-bold'>{station?.status}</div>
            </div>
        </div>
    )
}

export default ViewStation;