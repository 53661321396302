import * as VehiclePermissions from "../modules/VehiclePermissions";

import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Select from "react-select";
import axios from "axios";
import context from "../../../context";

const EditVehicle = ({ role }) => {
  const { id } = useParams();
  const [cars, setCars] = useState([]);
  const [fleets, setFleets] = useState([]);
  const [statuses,_] = useState([
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
    { label: 'Incomplete', value: 'incomplete' }
  ]);
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState("");
  const [selectedFleet, setSelectedFleet] = useState("");
  const [selectedCar, setSelectedCar] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [color, setColor] = useState("");
  const [selectedStatus, setSelectedStatus] = useState({ label: "Select Status", value: ''});

  useEffect(() => {
    fetchCars();
    fetchFleets();
    fetchVehicle();
  },[]);

  useEffect(()=>{
    if(selectedFleet.value != null)
    {
      fetchDrivers();
    }
  },[selectedFleet.value])

  const updateVehicle = () => {
    axios
      .put(`${context.SERVER_URL}/api/vehicles/${id}`, {
        data: {
          registrationNumber,
          car: selectedCar.value,
          color,
          fleet: selectedFleet.value,
          status: selectedStatus.value,
          driver: selectedDriver.value
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchDrivers = () =>{
		//const fleet = selectedTrailer.value.split("|")[0];
		axios.get(`${context.SERVER_URL}/api/users?roleFilter=driver&fleetFilter=${selectedFleet.value}`)
		.then((response)=>{
				const _drivers = response.data.users.map((driver)=>({ label: `fleet: ${driver.fleet?.name} - name: ${driver.firstName} - surname: ${driver.lastName}`, value: driver._id }))
				setDrivers(_drivers);

		}).catch((err)=>{
				console.log(err)
		});    
	}

  const fetchFleets = () => {
    axios
      .get(`${context.SERVER_URL}/api/fleets`)
      .then((response) => {
        const _fleets = response.data.fleets.map((fleet) => {
          return { label: fleet.name, value: fleet._id };
        });
        setFleets(_fleets);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchCars = () => {
    axios
      .get(`${context.SERVER_URL}/api/cars`)
      .then((response) => {
        const _cars = response.data.cars.map((car) => {
          return {
            label: `${car.make}-${car.model}-${car.year}`,
            value: car._id,
          };
        });
        setCars(_cars);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchVehicle = () => {
    axios
      .get(`${context.SERVER_URL}/api/vehicles/${id}`)
      .then((response) => {
        const { fleet, color, registrationNumber, car, status, driver } = response.data;
        setSelectedStatus({ label: status, value: status });
        setSelectedFleet({ label: fleet.name, value: fleet._id });
        setSelectedCar({
          label: `${car.make}-${car.model}-${car.year}`,
          value: car._id,
        });
        setColor(color);
        setRegistrationNumber(registrationNumber);
        if(driver != null)
        {
          setSelectedDriver({ label: `fleet: ${driver.fleet?.name} - name: ${driver.firstName} - surname: ${driver.lastName}`, value: driver._id });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="w-full bg-white py-8 px-16 p-3 overflow-y-auto">
      <div className="flex justify-between">
        <h1 className="text-primary-1 font-bold">Edit Vehicle</h1>
        <Link to={"/fleets"} className="text-gray-400 hover:text-gray-800 pr-2">
          Back
        </Link>
      </div>
      <div className="border border-gray-400 mt-1" />

      <div className="w-full md:w-1/2 mt-5">
        <label className="text-primary-1 font-bold">Company</label>
        <Select
          className="w-full"
          placeholder="Select Company"
          options={fleets}
          onChange={(value) => setSelectedFleet(value)}
          value={selectedFleet}
        />
      </div>

      <div className="w-full md:w-1/2 mt-5">
        <label className="text-primary-1 font-bold">Car</label>
        <Select
          className="w-full"
          placeholder="Select Car"
          options={cars}
          onChange={(value) => setSelectedCar(value)}
          value={selectedCar}
        />
      </div>

      <div className="w-full md:w-1/2 mt-5">
        <label className="text-primary-1 font-bold">Status</label>
        <Select
          className="w-full"
          placeholder="Select Status"
          options={statuses}
          onChange={(value) => setSelectedStatus(value)}
          value={selectedStatus}
        />
      </div>

      <div className="mt-5">
        <label className="text-primary-1 font-bold">
          Registration Number
        </label>
        <input
          className="w-full p-5 rounded-lg mt-2 border"
          type="text"
          value={registrationNumber}
          onChange={(e) => setRegistrationNumber(e.target.value)}
          placeholder={"Enter Registraion Number"}
        />
      </div>
      <div className="mt-5">
        <label className="text-primary-1 font-bold">Color</label>
        <input
          className="w-full p-5 rounded-lg mt-2 border"
          type="text"
          value={color}
          onChange={(e) => setColor(e.target.value)}
          placeholder={"Enter Color"}
        />
      </div>

      <div className="mt-5">
        <label className="text-primary-1 font-bold">Driver</label>
        <Select
          className="w-full"
          placeholder="Select Driver"
          options={drivers}
          onChange={(value) => setSelectedDriver(value)}
          value={selectedDriver}
        />
      </div>

      <div className="">
        <button
          className="py-2 px-3 bg-primary-1 text-white font-bold rounded-md mt-5"
          onClick={() => updateVehicle()}
        >
          Update
        </button>
        {VehiclePermissions.isAuthorized("view", role) && (
          <Link
            to={`/vehicles/view/${id}`}
            className="border px-4 py-2 rounded-md ml-2 text-center hover:text-neutral-7 "
          >
            Cancel
          </Link>
        )}
      </div>
    </div>
  );
};

export default EditVehicle;
