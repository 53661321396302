import {
    Autocomplete,
    useJsApiLoader,
} from "@react-google-maps/api";
import React, {useRef, useState} from "react"
import { useNavigate } from "react-router-dom";

import context from "../../../context";

const libraries = ["places"];


const Profile = ({
    firstName,
    lastName,
    role,
    passport,
    company,
    dob,
    nationalId,
    address,
    contactNumber,
    status,
    email,
    fleet,
    id
  }) => {
    
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: context.GOOGLE_API_KEY,
        libraries
    });
    const addressRef = useRef();
    const navigate = useNavigate();

    const [editedFirstName, setEditedFirstName] = useState(firstName);
  const [editedLastName, setEditedLastName] = useState(lastName);
  const [editedRole, setEditedRole] = useState(role);
  const [editedAddress, setEditedAddress] = useState(address);
  const [editedPassport, setEditedPassport] = useState(passport);
  const [editedCompany, setEditedCompany] = useState(company?.name);
  const [editedFleet, setEditedFleet] = useState(fleet);
  const [editedDob, setEditedDob] = useState(dob);
  const [editedNationalId, setEditedNationalId] = useState(nationalId);
  const [editedContactNumber, setEditedContactNumber] = useState(contactNumber);
  const [editedStatus, setEditedStatus] = useState(status);
  const [editedEmail, setEditedEmail] = useState(email);

  // Define a generic handler function to update the edited value of each field
  const handleFieldChange = (event, setState) => {
    setState(event.target.value);
  };

  

    const [locationAutocomplete,setLocationAutocomplete] = useState(null);

    const onUserValueChanged = (key, value) =>{

        setUser((prevState)=>{
            return{
                ...prevState,
                [key]: value
            }
        })
    }

    const [user, setUser] = useState({
        firstName,
        lastName,
        role,
        address,
        passport,
        company,
        fleet,
        dob,
        nationalId,
        contactNumber,
        status,
        email
    });

    const updateUser = () => {};

    if ( !isLoaded)
    {
        return(
            <div className="w-full h-full p-5">
                <h1 className="text-gray-500 font-bold">Edit Booking</h1>
                <div className="h-screen flex justify-center items-center w-full">
        <div role="status">
          <svg
            aria-hidden="true"
            class="w-28 h-28 mr-2 text-gray-200 animate-spin dark:text-primary-1 fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
            </div>
        )
    }

    return(
        <div className="w-full h-full p-3 bg-white">
            <h1>Profile</h1>

            {/* Settings forms */}
            <div className="divide-y divide-white/5">
              <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16  md:grid-cols-3">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-primary-1">Personal Information</h2>
                  <p className="mt-1 text-sm leading-6 text-gray-400">
                    Use a permanent address where you can receive mail.
                  </p>
                </div>

                <form className="md:col-span-2">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div className="col-span-full flex items-center gap-x-8">
                      <img
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                        className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
                      />
                      <div>
                        <button
                          type="button"
                          className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-primary-1 shadow-sm hover:bg-white/20"
                        >
                          Change avatar
                        </button>
                        <p className="mt-2 text-xs leading-5 text-gray-400">JPG, GIF or PNG. 1MB max.</p>
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-primary-1">
                        First name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          autoComplete="given-name"
                          value={editedFirstName}
                          onChange={(e) => handleFieldChange(e, setEditedFirstName)}
                          className="block w-full rounded-md border border-primary-2 bg-white/5 py-1.5 px-2 text-primary-1 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-shade-2 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-primary-1">
                        Last name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          value={editedLastName}
                            onChange={(e) => handleFieldChange(e, setEditedLastName)}
                          autoComplete="family-name"
                          className="block w-full rounded-md border border-primary-2 bg-white/5 py-1.5 px-2 text-primary-1 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-shade-2 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-primary-1">
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                            value={editedEmail}
                            onChange={(e) => handleFieldChange(e, setEditedEmail)}
                          autoComplete="email"
                          className="block w-full rounded-md border border-primary-2 bg-white/5 py-1.5 px-2 text-primary-1 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-shade-2 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="username" className="block text-sm font-medium leading-6 text-primary-1">
                        Username
                      </label>
                      <div className="mt-2">
                        <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-shade-2">
                          <span className="flex select-none items-center pl-3 text-gray-400 sm:text-sm">
                            wheelsitnot.com/
                          </span>
                          <input
                            type="text"
                            name="username"
                            id="username"
                            autoComplete="username"
                            className="flex-1  border-primary-2 bg-transparent py-1.5 pl-1 text-primary-1 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="tawona"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="timezone" className="block text-sm font-medium leading-6 text-primary-1">
                        Timezone
                      </label>
                      <div className="mt-2">
                        <select
                          id="timezone"
                          name="timezone"
                          className="block w-full rounded-md border border-primary-2 bg-white/5 py-1.5 px-2 text-primary-1 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-shade-2 sm:text-sm sm:leading-6 [&_*]:text-black"
                        >
                          <option>Pacific Standard Time</option>
                          <option>Eastern Standard Time</option>
                          <option>Greenwich Mean Time</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="mt-8 flex">
                    <button
                      type="submit"
                      className="hidden lg:inline-flex items-center justify-center px-5 py-2.5 text-base transition-all duration-200 hover:bg-black hover:no-underline hover:text-white focus:text-black  font-semibold text-black border-2 border-black rounded-md"
                                         >
                      Save
                    </button>
                  </div>
                </form>
              </div>

              <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16  md:grid-cols-3">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-primary-1">Company Information</h2>
                  <p className="mt-1 text-sm leading-6 text-gray-400">
                    Use a permanent address where you can receive mail.
                  </p>
                </div>

                <form className="md:col-span-2">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div className="col-span-full flex items-center gap-x-8">
                      <img
                        src="https://www.etapath.com/assets/logo-b300cd6ef0957b7fda7f8bf116002f595d4e9b92763043a2af17ad4c97cfbec1.svg"
                        alt=""
                        className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
                      />
                      <div>
                        <button
                          type="button"
                          className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-primary-1 shadow-sm hover:bg-white/20"
                        >
                          Change Logo
                        </button>
                        <p className="mt-2 text-xs leading-5 text-gray-400">JPG, GIF or PNG. 1MB max.</p>
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-primary-1">
                        Company Name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          value={editedCompany}
                            onChange={(e) => handleFieldChange(e, setEditedCompany)}
                          autoComplete="given-name"
                          className="block w-full rounded-md border border-primary-2 bg-white/5 py-1.5 px-2 text-primary-1 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-shade-2 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-primary-1">
                        Representative
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          className="block w-full rounded-md border border-primary-2 bg-white/5 py-1.5 px-2 text-primary-1 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-shade-2 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-primary-1">
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          className="block w-full rounded-md border border-primary-2 bg-white/5 py-1.5 px-2 text-primary-1 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-shade-2 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="username" className="block text-sm font-medium leading-6 text-primary-1">
                        Username
                      </label>
                      <div className="mt-2">
                        <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-shade-2">
                          <span className="flex select-none items-center pl-3 text-gray-400 sm:text-sm">
                            wheelsitnot.com/
                          </span>
                          <input
                            type="text"
                            name="username"
                            id="username"
                            autoComplete="username"
                            className="flex-1  border-primary-2 bg-transparent py-1.5 pl-1 text-primary-1 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="etapath"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="timezone" className="block text-sm font-medium leading-6 text-primary-1">
                        Timezone
                      </label>
                      <div className="mt-2">
                        <select
                          id="timezone"
                          name="timezone"
                          className="block w-full rounded-md border border-primary-2 bg-white/5 py-1.5 px-2 text-primary-1 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-shade-2 sm:text-sm sm:leading-6 [&_*]:text-black"
                        >
                          <option>Pacific Standard Time</option>
                          <option>Eastern Standard Time</option>
                          <option>Greenwich Mean Time</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="mt-8 flex">
                    <button
                      type="submit"
                      className="hidden lg:inline-flex items-center justify-center px-5 py-2.5 text-base transition-all duration-200 hover:bg-black hover:no-underline hover:text-white focus:text-black  font-semibold text-black border-2 border-black rounded-md"
                                         >
                      Save
                    </button>
                  </div>
                </form>
              </div>

              
              <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-primary-1">Manage billing</h2>
                  <p className="mt-1 text-sm leading-6 text-gray-400">
                    Add or remove credit cards, manage default credit card to be used for purchases, remove saved credit cards and payment methods.
                  </p>
                </div>
                <button
                  onClick={()=> navigate(`/payments/user/${id}/cards`)}
                  className="h-fit lg:inline-flex bg-warning-1 items-center justify-center px-5 py-2.5 text-base transition-all duration-200 hover:bg-danger-2 hover:no-underline hover:text-white focus:text-black  font-semibold text-white  rounded-md"
                >
                  Go to manage
                </button>
              </div>

              <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-primary-1">Change password</h2>
                  <p className="mt-1 text-sm leading-6 text-gray-400">
                    Update your password associated with your account.
                  </p>
                </div>

                <form className="md:col-span-2">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div className="col-span-full">
                      <label htmlFor="current-password" className="block text-sm font-medium leading-6 text-primary-1">
                        Current password
                      </label>
                      <div className="mt-2">
                        <input
                          id="current-password"
                          name="current_password"
                          type="password"
                          autoComplete="current-password"
                          className="block w-full rounded-md border border-primary-2 bg-white/5 py-1.5 px-2 text-primary-1 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-shade-2 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="new-password" className="block text-sm font-medium leading-6 text-primary-1">
                        New password
                      </label>
                      <div className="mt-2">
                        <input
                          id="new-password"
                          name="new_password"
                          type="password"
                          autoComplete="new-password"
                          className="block w-full rounded-md border border-primary-2 bg-white/5 py-1.5 px-2 text-primary-1 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-shade-2 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-primary-1">
                        Confirm password
                      </label>
                      <div className="mt-2">
                        <input
                          id="confirm-password"
                          name="confirm_password"
                          type="password"
                          autoComplete="new-password"
                          className="block w-full rounded-md border border-primary-2 bg-white/5 py-1.5 px-2 text-primary-1 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-shade-2 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-8 flex">
                  <button
                      type="submit"
                      className="hidden lg:inline-flex items-center justify-center px-5 py-2.5 text-base transition-all duration-200 hover:bg-black hover:no-underline hover:text-white focus:text-black  font-semibold text-black border-2 border-black rounded-md"
                                         >
                      Save
                    </button>
                  </div>
                </form>
              </div>

              <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-primary-1">Log out other sessions</h2>
                  <p className="mt-1 text-sm leading-6 text-gray-400">
                    Please enter your password to confirm you would like to log out of your other sessions across all of
                    your devices.
                  </p>
                </div>

                <form className="md:col-span-2">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div className="col-span-full">
                      <label htmlFor="logout-password" className="block text-sm font-medium leading-6 text-primary-1">
                        Your password
                      </label>
                      <div className="mt-2">
                        <input
                          id="logout-password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          className="block w-full rounded-md border border-primary-2 bg-white/5 py-1.5 px-2 text-primary-1 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-shade-2 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-8 flex">
                    
                    <button
                      type="submit"
                      className="hidden lg:inline-flex items-center justify-center px-5 py-2.5 text-base transition-all duration-200 hover:bg-black hover:no-underline hover:text-white focus:text-black  font-semibold text-black border-2 border-black rounded-md"
                                         >
                      Log out other sessions
                    </button>
                  </div>
                </form>
              </div>

              <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-primary-1">Delete account</h2>
                  <p className="mt-1 text-sm leading-6 text-gray-400">
                    No longer want to use our service? You can delete your account here. This action is not reversible.
                    All information related to this account will be deleted permanently.
                  </p>
                </div>

                <form className="flex items-start md:col-span-2">
                  
                  <button
                      type="submit"
                      className="hidden lg:inline-flex bg-warning-1 items-center justify-center px-5 py-2.5 text-base transition-all duration-200 hover:bg-danger-2 hover:no-underline hover:text-white focus:text-black  font-semibold text-white  rounded-md"
                                         >
                      Yes, delete my account
                    </button>
                </form>
              </div>
            </div>

            <div className="flex flex-wrap w-full p-4">
                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    {
                        ["logisticsSpecialist","admin", "companyManager", "user", "companyAdmin"].includes(user.role) &&
                        <>
                            <label className = "font-bold text-primary-1 text-xs">Company</label>
                            <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs" >{user.company?.name}</div>
                        </>
                    }
                    {
                        ["driver", "fleetAdmin", "fleetManager"].includes(role) &&
                        <>
                            <label className = "font-bold text-primary-1 text-xs">Fleet</label>
                            <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs" >{user.fleet?.name}</div>
                        </>
                    }
                </div>
                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Name</label>
                    <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs">{user.firstName}</div>
                </div>

                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Surname</label>
                    <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs">{user.lastName}</div>
                </div>

                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Email</label>
                    <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs">{user.email}</div>
                </div>
                
                <div className="w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Contact:</label>
                    <input 
                        className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs"
                        type="number"
                        onChange = { e => onUserValueChanged("contactNumber",e.target.value) } 
                        placeholder = {"Enter contact"}
                        value = {user.contactNumber}
                    />
                </div>

                <div className="w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Passport:</label>
                    <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs">{user.passport}</div>
                </div>

                <div className="w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">National ID:</label>
                    <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs">{user.nationalId}</div>
                </div>
                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Address</label>
                    <Autocomplete      
                        onPlaceChanged={() => {

                            if (locationAutocomplete !== null) {

                                const place = locationAutocomplete.getPlace();
                                const { formatted_address} = place;
                                onUserValueChanged("address", formatted_address)      
                            }
                            else
                            {
                                console.log('Autocomplete is not loaded yet!')
                            }
                        }}
                        onLoad={(autocomplete)=>{
                            setLocationAutocomplete(autocomplete);
                        }}
                        options={{
                            types: ["address"],
                            //componentRestrictions: { country: "za" },
                        }}
                        //defaultValue={user.address}
                    >
                        <input ref={addressRef} className="flex w-full h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs" type="text"></input>
                    </Autocomplete>
                </div>

                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Status</label>
                    <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs">{user.status}</div>
                </div>
                <div className = "w-full flex flex-col mt-2 pr-2 md:w-1/4">
                    <label className = "font-bold text-primary-1 text-xs">Role</label>
                    <div className = "flex h-10 items-center pl-3 bg-white border-1 border-gray-300 rounded-lg shadow text-gray-500 text-xs">{user.role}</div>
                </div>

                <button 
                    className="bg-primary-1 rounded-lg text-white font-semibold w-full py-3 mt-10 mb-10 md:w-1/4 md:mt-2 md:mb-0 md:py-0"
                    onClick= {()=>updateUser()}
                >
                    Update
                </button>
            </div>
            
        </div>
    )
}

export default Profile;
