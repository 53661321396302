import React, { useState} from 'react';
// import * as GreipService from '../../../services/GreipService';
import * as ApiLayerService from '../../../services/ApiLayerService';

const CreateCard = ({
    entityId,
    setShowNewCardDialog,
    entity,
    userId
}) => {

    const [cardNickname, setCardNickname] = useState('');
    const [cardDetails, setCardDetails] = useState(null);
    const [cardBinNumber, setCardBinNumber] = useState(null);

    const getCarDetails = async (number) => {
        try {
            // const geripDetails = await GreipService.binLookup(number);
            const apiLayerCardDetails = await ApiLayerService.binLookup(number);
            setCardDetails(apiLayerCardDetails);
        } catch (error) {
            console.log(error)
        }
    }

    const hasInvalidNickName = () =>{
        return cardNickname?.length < 6;
    }

    const hasInvalidBinNumber = () =>{
        return cardBinNumber?.length != 6;
    }

    const CreatePayfastCard = () => {
        return (
            <form action={process.env.REACT_APP_PAYFAST_URL} method="post">
                <input type="hidden" name="merchant_id" value={process.env.REACT_APP_PAYFAST_MERCHANT_ID}/>
                <input type="hidden" name="merchant_key" value={process.env.REACT_APP_PAYFAST_MERCHANT_KEY}/>
                <input type="hidden" name="return_url" value={`${window.location.origin}/payments/${entity}/${entityId}/cards`}/>
                <input type="hidden" name="cancel_url" value={`${window.location.origin}/payments/${entity}/${entityId}/cards`}/>
                <input type="hidden" name="notify_url" value={`${process.env.REACT_APP_SERVER_URL}/api/cards/subscribe`}/>
                <input type="hidden" name="item_name" value={entityId.toString()}/>
                <input type="hidden" name="amount" value={"0.00"}/>
                <input type="hidden" name="test" value={true}/>
                <input type="hidden" name="subscription_type" value="2"/>

                <input type="hidden" name="custom_str1" value={entity}/>
                <input type="hidden" name="custom_str2" value={entityId.toString()}/>
                <input type="hidden" name="custom_str3" value={userId}/>
                <input type="hidden" name="custom_str4" value={
                    JSON.stringify({
                        cardNickname,
                        countryCode: cardDetails.countryCode,
                        scheme: cardDetails?.scheme?.toLowerCase(),
                        type: cardDetails?.type?.toLowerCase(),
                        gateway: "payfast"
                    })
                }/>
                <input 
                    className={`${ hasInvalidNickName() ? "bg-gray-200" : "bg-primary-1" } text-white rounded-md py-2 px-3`}
                    disabled={ hasInvalidNickName() || cardDetails?.countryCode == null}
                    value='create'
                    type="submit" 
                    target="_blank"
                />
            </form>
        )
    }

    const CreatePaynowCard = ({

    }) => {
        return (
            <button
                className={`${ cardNickname == '' ? "bg-gray-200" : "bg-primary-1" } text-white rounded-md py-2 px-3`}
                disabled={cardNickname == '' || cardDetails?.countryCode == null}
                value='create'
                type="submit" 
                target="_blank"
            >
                create
            </button>
        )
    }

    return (
        <div className="flex flex-col items-center absolute w-1/2 h-1/2 transform -translate-x-1/2 -translate-y-1/2 border border-gray-400 self-center top-1/2 left-1/2 rounded-md p-10 bg-gray-100">
            <div
                className='flex flex-row w-full items-center justify-end w-full'
            >
                <button
                    className='bg-primary-1 text-white rounded-md py-2 px-3'
                    onClick={()=> setShowNewCardDialog(false)}
                >
                    Cancel
                </button>
            </div>
            <div className='flex flex-row gap-4 items-center justify-center mb-2'>
                <input
                    className='border border-gray-200 mt-2 p-2 rounded-md w-full'
                    placeholder='Enter the first 6 digits of your card'
                    onChange={({target: {value}})=> {
                        if (value.length > 6) return;
                        if (cardDetails != null) setCardDetails(null);
                        setCardBinNumber(value)
                    }}
                    value={cardBinNumber}
                    type='number'
                />
                <button
                    className={`${hasInvalidBinNumber() ? "bg-gray-500" : "bg-primary-1"} text-white rounded-md py-2 px-3 mt-2`}
                    onClick={()=> getCarDetails(cardBinNumber)}
                    disabled={hasInvalidBinNumber()}
                >
                    Process
                </button>
            </div>
            {
                cardDetails?.countryCode != null &&
                <>
                    <div
                        className='flex flex-col items-center mb-2'
                    >
                        <label className='text-primary-1 font-bold text-md'>Card Nickname</label>
                        <input
                            className='border border-gray-200 mt-2 p-2 rounded-md w-full'
                            placeholder='Enter card nickname'
                            onChange={({target: {value}})=> setCardNickname(value)}
                            value={cardNickname}
                            maxLength={10}
                            minLength={6} 
                        />
                    </div>
                    {
                        cardDetails?.countryCode === 'ZW' ?
                            <CreatePaynowCard/>
                        :
                            <CreatePayfastCard/>
                    }
                </>
            }
        </div>
    )
}
export default CreateCard;