import {connect} from 'react-redux'
import Navigation from '../components/Navigation';
import { 
} from '../modules/navigation'
import {
    logoutAction
} from "../../login/modules/login"

const mapStateToProps = (state) =>({
    userToken: (state.login.userToken == null) ? false: true,
    ability: state.login.ability,
    firstName: state.profile.firstName,
    role: state.profile.role
});

const mapActionCreators = {
    logoutAction
};

export default connect (mapStateToProps,mapActionCreators)(Navigation); 