import update from 'react-addons-update'
import actionConstants from './actionConstants'
import axios from 'axios';
import context from '../../../context/index';
import { showErrorAction, showLoaderAction } from '../../common/modules/modal';
import { toast } from 'react-toastify';
export const initialLoginState = {
    userToken: null,
    ability: null,   
};


const {
    LOGIN,
    LOGOUT
} = actionConstants;

export const loginAction = ({email, password, navigate}) =>{

    return (dispatch, store) => {

        dispatch(showLoaderAction(true))
        axios.post(`${context.SERVER_URL}/api/login`,
        {
            email,
            password
        })
        .then(res=>{
            const { 
                userToken,
                ability, 
                user:{
                    firstName,
                    lastName,
                    role,
                    passport,
                    company,
                    dob,
                    nationalId,
                    address,
                    contactNumber,
                    status,
                    email,
                    fleet,
                    _id
                } 
            } = res.data;

            dispatch({
                type: LOGIN,
                payload:{
                    userToken,
                    firstName,
                    lastName,
                    role,
                    passport,
                    company,
                    dob,
                    nationalId,
                    address,
                    contactNumber,
                    status,
                    email,
                    fleet,
                    id: _id,
                    ability
                }
            })
            
            navigate("/")
        })
        .catch(err=>{
            //alert("wrong user name and password");
            dispatch(showErrorAction({
                showError: true,
                errorMessage: err.response?.data?.message || "An error occured."
            }))
            console.log(err.response.data)
        }).finally(()=>{
            dispatch(showLoaderAction(false))
        })
    }    
}
export const logoutAction = () =>{

    //localStorage.removeItem('persist:root')
    return (dispatch, store) => {
        dispatch({
            type: LOGOUT,
            payload:{
                userToken: null,
                firstName: null,
                lastName: null,
                role: null,
                passport: null,
                company: null,
                dob: null,
                nationalId: null,
                address: null,
                contactNumber: null,
                status: null,
                email: null,
                fleet: null,
                id: null,
                ability: null
            }
        })
    }    
}


function handleLoginAction (state, action){
    return update(state, {
        login:{
            userToken: { $set: action.payload.userToken },
            ability: { $set: action.payload.ability }
        },
        profile:{
            firstName: { $set: action.payload.firstName },
            lastName: { $set: action.payload.lastName},
            role: { $set: action.payload.role },
            passport: { $set: action.payload.passport },
            company: { $set: action.payload.company },
            dob: { $set: action.payload.dob },
            nationalId: { $set: action.payload.nationalId },
            address: { $set: action.payload.address },
            contactNumber: { $set: action.payload.contactNumber },
            status: { $set: action.payload.status },
            email: { $set: action.payload.email },
            fleet: { $set: action.payload.fleet },
            id: { $set: action.payload.id }
        }
    })
}

export const LOGIN_ACTION_HANDLERS = {
    LOGIN: handleLoginAction,
    LOGOUT: handleLoginAction
}



