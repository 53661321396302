import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import context from "../../../context/index";
import { SUPPORT_CATEGORIES } from "../../../util/general.util";
import Select from "react-select";

const NewSupport = ({ createSupportAction }) => {
    const location = useLocation();
    const { booking, trip } = location.state || {};

    const [category, setCategory] = useState({ label: "Select Category", value: '' });
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    return (
        <div className="w-full h-full p-5 bg-white text-black">
            <h1 className="text-2xl font-bold text-black">New Support Ticket</h1>
            <div className='border-b border-gray-300 my-4' />
            <div className='text-gray-600 mb-8'>
                <Link
                    to="/supports"
                    className="underline text-blue-600 hover:text-blue-800"
                >
                    Support Tickets
                </Link>
            </div>

            <div className="mt-5">
                <label className="text-lg font-semibold text-black">Category</label>
                <Select
                    className="w-full mt-2"
                    placeholder="Select Category"
                    options={SUPPORT_CATEGORIES}
                    onChange={(value) => setCategory(value)}
                    value={category}
                    styles={{
                        control: (base) => ({
                            ...base,
                            borderColor: 'black',
                            boxShadow: 'none',
                            "&:hover": {
                                borderColor: 'black',
                            }
                        }),
                        menu: (base) => ({
                            ...base,
                            backgroundColor: 'white',
                        }),
                        singleValue: (base) => ({
                            ...base,
                            color: 'black',
                        }),
                    }}
                />
            </div>

            <div className="mt-5">
                <label className="text-lg font-semibold text-black">Message</label>
                <textarea
                    className="w-full p-4 mt-2 rounded-lg border border-gray-300 focus:border-black focus:ring-black"
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Add information regarding the support ticket"
                    rows="5"
                />
            </div>

            <button
                className="py-3 px-6 bg-black text-white font-bold rounded-md mt-6 hover:bg-gray-800 transition duration-200"
                onClick={() => createSupportAction({
                    booking: booking?._id,
                    trip: trip?._id,
                    company: booking?.company?._id,
                    fleet: trip?.fleet?._id,
                    message,
                    category: category?.value
                }, navigate)}
            >
                Submit
            </button>
        </div>
    );
}

export default NewSupport;
