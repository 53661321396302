import React, { useEffect, useState } from "react";
import axios from "axios";
import context from "../../../context";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as PaymentPermissions from "../modules/PaymentPermissions";
import CreateCard from "./CreateCard";

const Cards = ({ role, userId }) => {

  const [showNewCardDialog, setShowNewCardDialog] = useState(false);
  const [cards, setCards] = useState({
    companyCards: [],
    userCards: [],
  });
  const { entity, entityId } = useParams();

  useEffect(() => {
    fetchCards();
  }, []);

  const fetchCards = () => {
    axios
      .get(`${context.SERVER_URL}/api/cards`)
      .then((response) => {
        const groupedCards = response.data.reduce((grouped, card) => {
          const isCompanyCard = card.ownerType === "CompanyModel";
          if (isCompanyCard) {
            grouped.companyCards.push(card);
          } else if (card.ownerType === "UserModel") {
            grouped.userCards.push(card);
          }
          return grouped;
        },
        {
          companyCards: [],
          userCards: [],
        })
        setCards(groupedCards);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderCard = (card, index) => {
    return (
      <tr className="border-b border-gray-200" key={index}>
        <td className="p-3 text-gray-800">{card.cardNickname}</td>
        <td className="p-3 text-gray-800">{card.createdAt}</td>
        <td className="p-3 text-gray-800">{card.lastCardDigits}</td>
        {PaymentPermissions.isAuthorized("delete", role) && (
          <td className="p-3 text-right">
            <button
              className="px-4 py-2 text-sm text-red-600 bg-red-100 border border-red-300 rounded hover:bg-red-200"
              onClick={() => deleteCard(card._id)}
            >
              Delete
            </button>
          </td>
        )}
      </tr>
    );
  };

  const deleteCard = (id) => {
    const options = {
      title: "Delete Card",
      message: "Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            axios
              .delete(`${context.SERVER_URL}/api/card/${id}`)
              .then((response) => {
                fetchCards(); // Refresh the list after deletion
              })
              .catch((error) => {
                console.log(error);
              });
          },
        },
        {
          label: "No",
        },
      ],
      overlayClassName: "overlay-custom-class-name",
    };

    confirmAlert(options);
  };

  return (
    <div className="w-full h-full p-6 bg-white shadow rounded-lg overflow-y-auto">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-primary-1">Cards</h1>
        <button
          className="bg-primary-1 text-white py-2 px-4 rounded-md hover:bg-primary-2"
          onClick={() => setShowNewCardDialog(true)}
        >
          New Card
        </button>
      </div>
      <div className="border-b border-gray-300 mb-6"></div>

      {  
        cards.companyCards.length > 0 &&
        <>
          <h1 className="text-lg font-bold text-primary-1">Company Cards</h1>
          <table className="w-full">
            <thead className="border-b-2 border-gray-300">
              <tr>
                <th className="p-3 text-green-custom text-left">Card Nickname</th>
                <th className="p-3 text-green-custom text-left">Created At</th>
                <th className="p-3 text-green-custom text-left">Last Digits</th>
                <th className="p-3 text-left"></th>
              </tr>
            </thead>
            <tbody>
              {cards.companyCards.map((card, index) => {
                return renderCard(card, index);
              })}
            </tbody>
          </table>
        </>
      }
      {
        cards.userCards.length > 0 &&
        <>
          <h1 className="text-lg font-bold text-primary-1">User Cards</h1>
          <table className="w-full">
            <thead className="border-b-2 border-gray-300">
              <tr>
                <th className="p-3 text-green-custom text-left">Card Nickname</th>
                <th className="p-3 text-green-custom text-left">Created At</th>
                <th className="p-3 text-green-custom text-left">Last Digits</th>
                <th className="p-3 text-left"></th>
              </tr>
            </thead>
            <tbody>
              {cards.userCards.map((card, index) => {
                return renderCard(card, index);
              })}
            </tbody>
          </table>
        </>
      }
      {showNewCardDialog && (
        <CreateCard
          userId={userId}
          entityId={entityId}
          entity={entity}
          setShowNewCardDialog={setShowNewCardDialog}
        />
      )}
    </div>
  );
};

export default Cards;
