import * as WalletPermissions from '../modules/WalletPermissions'

import {Link, useParams} from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import axios from 'axios'
import context from '../../../context'

const ViewWallet = ({
    role,
}) =>{

    const {id} = useParams();
    const [wallet, setWallet] = useState({})
    const [depositAmount, setDepositAmount] = useState(0);
    const [withdrawAmount, setWithdrawAmount] = useState(0);
    const [transactions, setTransactions] = useState([]);

    const fetchWallet = () => {
        axios.get(`${context.SERVER_URL}/api/wallets/${id}`)
        .then((response)=>{
            const wallet = response.data;
            setWallet(wallet);
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    const fetchTransactions = () => {
        axios.get(`${context.SERVER_URL}/api/transactions/${id}`)
        .then((response)=>{
            const transactions = response.data.transactions;
            setTransactions(transactions);
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    const depositWallet = () => {
        axios.put(`${context.SERVER_URL}/api/wallets/${id}/deposit`,{
            data:{
                amount: depositAmount,
                currency: wallet.currency
            }
        })
        .then((response)=>{
            console.log(response);
            fetchWallet();
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    const withdrawWallet = () => {
        axios.put(`${context.SERVER_URL}/api/wallets/${id}/withdraw`,{
            data:{
                amount: withdrawAmount,
                currency: wallet.currency
            }
        })
        .then((response)=>{
            console.log(response);
            fetchWallet();
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    useEffect(()=>{
        fetchWallet();
        fetchTransactions();
    },[])

    console.log("transactions", transactions);
    return(
        <div className='p-3'>
            <div className='flex flex-row'>
                <Link 
                    to = {"/wallets"}
                    className = "underline pr-2"
                >wallets</Link> | 
                {
                    WalletPermissions.isAuthorized("edit",role) &&
                    <Link 
                        to = {`/cars/edit/${id}`}
                        className = "underline pl-2"
                    >edit</Link>
                }
            </div>
            <h1 className='text-primary-2 text-lg font-bold'>View Wallet</h1>
            <div className='grid grid-cols-5 w-full gap-4 mt-4'>
                <div className='col-span-1 flex flex-col'>
                    <label className='text-primary-2 font-bold mr-2'>Name:</label>
                    <div className='text-gray-500 font-bold'>{wallet?.ownerType == "UserModel" ? `${wallet?.owner?.firstName} ${wallet?.owner?.lastName}` : wallet?.name}</div>
                </div>
                <div className='col-span-1 flex flex-col'>
                    <label className='text-primary-2 font-bold mr-2'>Ower Type:</label>
                    <div className='text-gray-500 font-bold'>{wallet?.ownerType}</div>
                </div>
                <div className='col-span-1 flex flex-col'>
                    <label className='text-primary-2 font-bold mr-2'>Currency:</label>
                    <div className='text-gray-500 font-bold'>{wallet?.currency}</div>
                </div>
                <div className='col-span-1 flex flex-col'>
                    <label className='text-primary-2 font-bold mr-2'>Tokens:</label>
                    <div className='text-gray-500 font-bold'>{wallet?.tokens}</div>
                </div>

                <div className='col-span-1 flex flex-col'>
                    <label className='text-primary-2 font-bold mr-2'>Available Tokens:</label>
                    <div className='text-gray-500 font-bold'>{wallet?.availableTokens}</div>
                </div>
            </div>

            <div className='border border-gray-400 mt-5'/>

            <div className='text-primary-2 font-bold mt-5'>
                <label className='text-primary-2 font-bold mr-2'>Deposit</label>
                <div className='flex flex-row'>
                    <input className='text-gray-500 font-bold mr-4'
                        placeholder='Amount'
                        type='number'
                        value={depositAmount}
                        onChange={(e)=> setDepositAmount(e.target.value)}
                    />
                    <button 
                        className='bg-primary-1 rounded-md text-white px-3 py-2'
                        onClick={()=> depositWallet()}
                    >Deposit</button>
                </div>
            </div>

            <div className='text-primary-2 font-bold mt-5'>
                <label className='text-primary-2 font-bold mr-2'>Withdraw</label>
                <div className='flex flex-row'>
                    <input className='text-gray-500 font-bold mr-4'
                        placeholder='Amount'
                        type='number'
                        value={withdrawAmount}
                        onChange={(e)=> setWithdrawAmount(e.target.value)}
                    />
                    <button 
                        className='bg-primary-1 rounded-md text-white px-3 py-2'
                        onClick={()=> withdrawWallet()}
                    >Withdraw</button>
                </div>
            </div>

            <div className='mt-5'>
                <h1 className='text-primary-2 text-lg font-bold'>Transactions</h1>
                <table className='w-full mt-5'>
                    <thead>
                        <tr>
                            <th className='text-sm text-primary-2 font-bold px-2'>Id</th>
                            <th className='text-sm text-primary-2 font-bold px-2'>From</th>
                            <th className='text-sm text-primary-2 font-bold px-2'>To</th>
                            <th className='text-sm text-primary-2 font-bold px-2'>Tokens</th>
                            <th className='text-sm text-primary-2 font-bold px-2'>Amount</th>
                            <th className='text-sm text-primary-2 font-bold px-2'>Exchange Rate</th>
                            <th className='text-sm text-primary-2 font-bold px-2'>Currency</th>
                            <th className='text-sm text-primary-2 font-bold px-2'>Payment Method</th>
                            <th className='text-sm text-primary-2 font-bold px-2'>Status</th>
                            <th className='text-sm text-primary-2 font-bold px-2'>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            transactions.map((transaction, index)=>(
                                <tr key={index}>                 
                                    <td className='text-sm text-gray-500 font-bold px-2'>{transaction._id}</td>
                                    <td className='text-sm text-gray-500 font-bold px-2'>{transaction.walletFrom?.owner?.name || `${transaction.walletFrom?.owner?.firstName} ${transaction.walletFrom?.owner?.lastName}`}</td>
                                    <td className='text-sm text-gray-500 font-bold px-2'>{transaction.walletTo?.owner?.name || `${transaction.walletTo?.owner?.firstName} ${transaction.walletTo?.owner?.lastName}`}</td>
                                    <td className='text-sm text-gray-500 font-bold px-2'>{transaction.tokens}</td>
                                    <td className='text-sm text-gray-500 font-bold px-2'>{transaction.amount}</td>
                                    <td className='text-sm text-gray-500 font-bold px-2'>{transaction.exchangeRate}</td>
                                    <td className='text-sm text-gray-500 font-bold px-2'>{transaction.currency}</td>
                                    <td className='text-sm text-gray-500 font-bold px-2'>{transaction.paymentMethod}</td>
                                    <td className='text-sm text-gray-500 font-bold px-2'>{transaction.status}</td>
                                    <td className='text-sm text-gray-500 font-bold px-2'>{new Date(transaction.createdAt).toLocaleDateString()}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ViewWallet;