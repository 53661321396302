import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { createMongoAbility } from '@casl/ability';
import ForgotPassword from '../../password/ForgotPassword';
import LandingContainer from '../../landing/container/LandingContainer';
import LoginContainer from '../../login/container/LoginContainer';
import Modal from 'react-modal'
import NavBar from './NavBar';
import NewGuestBooking from '../../guest/NewGuestBooking';
import RegisterByInviteContainer from '../../register/container/RegisterByInviteContainer';
import RegisterContainer from '../../register/container/RegisterContainer';
import ResetPassword from '../../password/ResetPassword';
import ViewGuestBooking from '../../guest/ViewGuestBooking';
import { AbilityContext } from '../../../abilities/can';

const Navigation = ({
    userToken,
    logoutAction,
    firstName,
    role,
    ability
}) =>{

  useEffect(()=>{
    Modal.setAppElement('#navigation');
  },[])


  if(!userToken)
  {
    return (
      <div className=' h-screen' id="navigation" >
          <Router>
            <Routes>
            <Route 
                path = "/"
                element = {<LandingContainer/>}
              />
              <Route 
                path = "/login"
                element = {<LoginContainer/>}
              />
              <Route 
                path = "/register"
                element = {<RegisterContainer/>}
              />
              <Route 
                path = "/registerbyinvite/:token"
                element = {<RegisterByInviteContainer/>}
              />
              <Route 
                path = "/forgotpassword"
                element = {<ForgotPassword/>}
              />
              <Route 
                path = "/passwordresetbyinvite/:token"
                element = {<ResetPassword/>}
              />
              <Route
                path = "/newGuestBooking"
                element = {<NewGuestBooking/>}
              />
              <Route
                path = "/viewGuestBooking/:id"
                element = {<ViewGuestBooking/>}
              />
            </Routes>
           
        </Router>
      </div>

    )
  }

  const caslRules = ability?.M?.map((rule) => ({
    action: rule.action,
    subject: rule.subject,
    conditions: rule.conditions,
  }));

  const userAbility = createMongoAbility(caslRules || []);
  
  return (
    <div className='w-screen  h-screen bg-neutral-1' id="navigation">    
      <AbilityContext.Provider value={userAbility}>
        <Router>
          <NavBar
            userToken ={userToken}     
            logoutAction = {logoutAction}
            firstName = {firstName} 
            role={role}
          />
        </Router>
      </AbilityContext.Provider>
    </div>
  );
}

export default Navigation;
