import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import * as PaymentPermissions from '../modules/PaymentPermissions';

const ViewPayment = ({ role }) => {
  const { id } = useParams();
  const [booking, setBooking] = useState(null);

  const renderPoint = (point, index) => {
    return (
      <div className="flex flex-col w-full mb-8 p-4 border-b border-gray-200" key={index}>
        <div className="flex flex-row w-full gap-8">
          <div className="flex flex-col w-1/2">
            <div className="text-lg font-medium text-gray-800">{point.address}</div>
            <div className="text-sm text-gray-500">{point?.type?.label}</div>
          </div>
          <div className="flex flex-col w-1/2">
            {point.capacity.map((product, idx) => (
              <div className="flex flex-row justify-between w-full text-gray-700" key={idx}>
                <div className="font-medium">{product.description}</div>
                <div className="text-right">{product.quantity}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const getBooking = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/bookings/${id}`);
      setBooking(response.data);
    } catch (error) {
      console.error('Failed to fetch booking:', error);
    }
  };

  useEffect(() => {
    getBooking();
  }, []);

  return (
    <div className="p-8 bg-white w-full">
      <div className="flex flex-row mb-6 text-lg font-medium text-primary-1">
        <Link to="/payments" className="hover:underline">Payments</Link>
        {PaymentPermissions.isAuthorized('view', role) && (
          <>
            <span className="text-gray-400 mx-2">|</span>
            <Link to={`/bookings/view/${id}`} className="hover:underline">Booking</Link>
          </>
        )}
      </div>
      <h1 className="text-3xl font-bold text-gray-900 mb-8">View Payment</h1>
      <div className="w-full mb-8 space-y-6">
        {booking?.path.map((point, index) => renderPoint(point, index))}
      </div>
      <div className="flex flex-col w-full mt-8">
        <div className="flex flex-row justify-between items-center bg-gray-50 p-4 rounded-lg">
          <label className="text-xl font-semibold text-green-600">Paid Fare</label>
          <div className="text-xl font-semibold text-green-600">{booking?.payment[0]?.amountGross}</div>
        </div>
      </div>
    </div>
  );
};

export default ViewPayment;
