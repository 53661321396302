import {connect} from 'react-redux'
import Cards from '../components/Cards';
const mapStateToProps = (state) =>({
    role:  state.profile.role,
    userId: state.profile._id
});

const mapActionCreators = {
};

export default connect (mapStateToProps,mapActionCreators)(Cards); 