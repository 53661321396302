import update from 'react-addons-update'
import actionConstants from './actionConstants'
import axios from 'axios';
import context from '../../../context/index';
import { showLoaderAction } from '../../common/modules/modal';

export const initialTripState = {
    trip: null,
    trips:[],
    totalPages: 100,
    currentPage: 1
};


const {
    GET_TRIPS_DATA,
    GET_TRIP_DATA,
    TRAILER_LOCATION_UPDATE,
    SET_IS_LOADING,
    ACCEPT_TRIP,
    ONROUTE_TRIP,
    DECLINE_TRIP,
    COMPLETE_TRIP,
    POINT_ARRIVED,
    POINT_LOADING,
    POINT_OFFLOADING,
    POINT_COMPLETE,
    SOCKET_TRIP_POINT_UPDATE,
    SOCKET_TRIP_UPDATE
} = actionConstants;


export const setIsLoadingAction = (isLoading) => {
    return (dispatch, store) => {
        dispatch({
            type: SET_IS_LOADING,
            payload: {
                isLoading
            }
        })
    }
}

export const getTripsDataAction = (filters) =>{

    return (dispatch, store) => {
        dispatch(showLoaderAction(true));
        const {searchFilter, dateFilter, timeslotFilter, statusFilter, fleetFilter, pageFilter, limitFilter, mileFilter, pathTypeFilter }= filters;
        let _date = '';
        if(dateFilter?.value != "")
        {
            _date = JSON.stringify({
                month: new Date(dateFilter?.value).getMonth(),
                date: new Date(dateFilter?.value).getDate(),
                year: new Date(dateFilter?.value).getFullYear()
            })
        }
       
        axios.get(`${context.SERVER_URL}/api/trips?searchFilter=${searchFilter}&dateFilter=${_date}&timeslotFilter=${timeslotFilter?.value}&statusFilter=${statusFilter?.value}&fleetFilter=${fleetFilter?.value}&pageFilter=${pageFilter}&limitFilter=${limitFilter}&mileFilter=${mileFilter?.value}&pathTypeFilter=${pathTypeFilter?.value}`)
        .then((res)=>{
            const { trips, totalPages, currentPage} = res.data;
            dispatch({
                type: GET_TRIPS_DATA,
                payload:{
                    trips,
                    totalPages,
                    currentPage
                }
            })
    
        }).catch((err)=>{
            console.log(err);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })   
    }    
}

export const getTripDataAction = (id) =>{
    return (dispatch, store) => {
        dispatch(showLoaderAction(true));
        axios.get(`${context.SERVER_URL}/api/trips/${id}`)
        .then((res) => {
            const trip = res.data;
            const trailerLocationCoordinates = trip?.trailer?.location[0]?.location?.coordinates;

            dispatch({
                type: GET_TRIP_DATA,
                payload:{
                    trip:{
                        ...trip,
                        trailerLocationCoordinates
                    }
                }
            })
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }    
}

export const acceptTripAction = (id) => {
    return (dispatch, store) => {
        dispatch(showLoaderAction(true));
        axios.put(`${context.SERVER_URL}/api/trips/${id}/accept`, {})
        .then((res) => {
            const status = res.data.status;
            dispatch({
                type: ACCEPT_TRIP,
                payload: {
                    status
                }
            })
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};

export const onrouteTripAction = (id) => {
    return (dispatch, store) => {
        dispatch(showLoaderAction(true));
        axios.put(`${context.SERVER_URL}/api/trips/${id}/onroute`, {})
        .then((res) => {
            const status = res.data.status;
            dispatch({
                type: ONROUTE_TRIP,
                payload: {
                    status
                }
            })
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};

export const declineTripAction = (id) => {
    return (dispatch, store) => {
        dispatch(showLoaderAction(true));
        axios.put(`${context.SERVER_URL}/api/trips/${id}/decline`, {})
        .then((res) => {
            const status = res.data.status;
            dispatch({
                type: DECLINE_TRIP,
                payload: {
                    status
                }
            })
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};

export const completeTripAction = (id) => {
    return (dispatch, store) => {
        dispatch(showLoaderAction(true));
        axios.put(`${context.SERVER_URL}/api/trips/${id}/complete`, {})
        .then((res) => {
            const status = res.data.status;
            dispatch({
                type: COMPLETE_TRIP,
                payload: {
                    status
                }
            })
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};

export const pointArrivedAction = (point) => {
    return async (dispatch, store) => {
        dispatch(showLoaderAction(true));
        axios.put(`${context.SERVER_URL}/api/bookings/${point.booking}/points/${point._id}/arrived`, {})
        .then((res) => {
            const trip = store()?.trips?.trip;  
            const pointIndex = trip.path.findIndex((p) => p._id === point._id);
            const status = res.data.status;
            dispatch({
                type: POINT_ARRIVED,
                payload: {
                    pointIndex,
                    status 
                }
            });
            dispatch(getTripDataAction(trip._id));
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};

export const pointLoadingAction = (point) => {
    return async (dispatch, store) => {
        dispatch(showLoaderAction(true));
        axios.put(`${context.SERVER_URL}/api/bookings/${point.booking}/points/${point._id}/loading`, {})
        .then((res) => {
            const trip = store()?.trips?.trip;
            const pointIndex = trip.path.findIndex((p) => p._id === point._id);
            const status = res.data.status;
            dispatch({
                type: POINT_LOADING,
                payload: {
                    pointIndex,
                    status 
                }
            });

            dispatch(getTripDataAction(trip._id));
        })
        .catch((error) => {
            console.log("error completing stop", error);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};

export const pointOffloadingAction = (point) => {
    return async (dispatch, store) => {

        axios.put(`${context.SERVER_URL}/api/bookings/${point.booking}/points/${point._id}/offloading`, {})
        .then((res) => {
            const trip = store()?.trips?.trip;
            const pointIndex = trip.path.findIndex((p) => p._id === point._id);
            const status = res.data.status;
            dispatch({
                type: POINT_OFFLOADING,
                payload: {
                    pointIndex,
                    status 
                }
            });

            dispatch(getTripDataAction(trip._id));
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};

export const pointCompleteAction = (point, confirmationCode) => {
    return async (dispatch, store) => {
        dispatch(showLoaderAction(true));
        axios.put(`${context.SERVER_URL}/api/bookings/${point.booking._id}/points/${point._id}/complete`, {
            confirmationCode
        })
        .then((res) => {
            const trip = store()?.trips?.trip;
            const pointIndex = trip.path.findIndex((p) => p._id === point._id);
            const status = res.data.status;
            dispatch({
                type: POINT_COMPLETE,
                payload: {
                    pointIndex,
                    status 
                }
            });

            dispatch(getTripDataAction(trip._id));
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};

export const startTrailerSearchAction = ({id, filters}) =>{
    return async (dispatch, store) => {
        dispatch(showLoaderAction(true));
        axios.put(`${context.SERVER_URL}/api/trips/${id}/startTrailerSearch`,{})
        .then((res)=>{
            dispatch(getTripsDataAction(filters));
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
}

export const trailerLocationUpdateAction = (trailer) =>{
    return (dispatch, store) => {
        dispatch({
            type: TRAILER_LOCATION_UPDATE,
            payload:{
                coordinates:trailer?.location?.coordinates
            }
        })
    }    
}


export const socketTripUpdateAction = (trip) =>{
    return (dispatch, store) => {
        dispatch({
            type: SOCKET_TRIP_UPDATE,
            payload:{
                trip
            }
        })
    }    
}

export const socketTripPointUpdateAction = (point) =>{
    return (dispatch, store) => {
        let trip = store()?.trips?.trip;
        if (trip?.id == point?.trip){

            let path = trip?.path?.map((p)=>{
                if(p?._d == point._id) return point;
                return p;
            });

            console.log("trip path", path);

            dispatch({
                type: SOCKET_TRIP_POINT_UPDATE,
                payload:{
                    path
                }
            })
        }
    }    
}


function handleSetIsLoading (state, action){
    return update(state, {
        isLoading: { $set: action.payload.isLoading }
    })
}

function handleGetTripsData (state, action){
    return update(state, {
        trips:{
            trips:{ $set: action.payload.trips },
            totalPages:{ $set: action.payload.totalPages },
            currentPage:{ $set: action.payload.currentPage },
        }
    })
}

function handleGetTripData (state, action){
    return update(state, {
        trips:{
            trip:{ $set: action.payload.trip },
        }
    })
}

function handleAcceptTrip (state, action){
    return update(state, {
        trips:{
            trip:{
                status: { $set: action.payload.status }
            },
        }
    })
}

function handleOnrouteTrip (state, action){
    return update(state, {
        trips:{
            trip:{
                status: { $set: action.payload.status },
            },
        }
    })
}

function handleDeclineTrip (state, action){
    return update(state, {
        trips:{
            trip:{
                status: { $set: action.payload.status }
            },
        }
    })
}

function handleCompleteTrip (state, action){
    return update(state, {
        trips:{
            trip:{
                status: { $set: action.payload.status }
            },
        }
    })
}

function handlePointArrived (state, action){
    return state;
    // let  path = store()?.trips?.trip?.path;
    // const pointIndex = action.payload.pointIndex;
    // path[pointIndex]?.status = action.payload.status;

    // return update(state, {
    //     trips:{
    //         trip:{
    //             path: { $set: path }
    //         },
    //     }
    // })
}

function handlePointLoading (state, action){
    return state;
    // let  path = store()?.trips?.trip?.path;
    // const pointIndex = action.payload.pointIndex;
    // path[pointIndex]?.status = action.payload.status;

    // return update(state, {
    //     trips:{
    //         trip:{
    //             path: { $set: path }
    //         },
    //     }
    // })
}

function handlePointOffloading (state, action){
    return state;
    // let  path = store()?.trips?.trip?.path;
    // const pointIndex = action.payload.pointIndex;
    // path[pointIndex]?.status = action.payload.status;

    // return update(state, {
    //     trips:{
    //         trip:{
    //             path: { $set: path }
    //         },
    //     }
    // })
}

function handlePointComplete (state, action){
    return state;
    // let  path = store()?.trips?.trip?.path;
    // const pointIndex = action.payload.pointIndex;
    // path[pointIndex]?.status = action.payload.status;

    // return update(state, {
    //     trips:{
    //         trip:{
    //             path: { $set: path }
    //         },
    //     }
    // })
}


function handleSocketTripPointUpdate (state, action){
    return update(state, {
        trips:{
            trip:{
                path: { $set: action.payload.path },
            },
        }
    })
}

function handleTrailerLocationUpdate (state, action){
    return update(state, {
        trips:{
            trip:{
                trailerLocationCoordinates: { $set: action.payload.coordinates }
            },
        }
    })
}

function handleSocketTripUpdate (state, action){
    return update(state, {
        trips:{
            trip:{
                status: { $set: action.payload.trip?.status },
            },
        }
    })
}

export const TRIPS_ACTION_HANDLERS = {
    GET_TRIPS_DATA: handleGetTripsData,
    GET_TRIP_DATA: handleGetTripData,
    TRAILER_LOCATION_UPDATE: handleTrailerLocationUpdate,
    ACCEPT_TRIP: handleAcceptTrip,
    ONROUTE_TRIP: handleOnrouteTrip,
    DECLINE_TRIP: handleDeclineTrip,
    COMPLETE_TRIP: handleCompleteTrip,
    POINT_ARRIVED: handlePointArrived,
    POINT_LOADING: handlePointLoading,
    POINT_OFFLOADING: handlePointOffloading,
    POINT_COMPLETE: handlePointComplete,
    SET_IS_LOADING: handleSetIsLoading,
    SOCKET_TRIP_UPDATE: handleSocketTripUpdate,
    SOCKET_TRIP_POINT_UPDATE: handleSocketTripPointUpdate
}