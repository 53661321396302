import * as TripPermissions from "../modules/TripPermissions";
import { vehicleIcon, MapStyles } from "../../../util/map.util";
import { Link, useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import context from "../../../context";
import * as TrailerLocationUpdateReceiver from "../../../sockets/receivers/TrailerLocationUpdateReceiver";
import * as PointUpdateReceiver from "../../../sockets/receivers/PointUpdateReceiver";
import * as TripUpdateReceiver from '../../../sockets/receivers/TripUpdateReceiver'
import { COLOR_CODES } from "../../../util/general.util";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
  MarkerClusterer
} from "@react-google-maps/api";
import OTPInput from "react-otp-input";
import {
  FaMapMarkedAlt,
  FaCar,
  FaCalendarAlt,
  FaClock,
  FaUser,
  FaTruck,
  FaRulerHorizontal,
  FaDollarSign
} from 'react-icons/fa';

const libraries = ["places"];

const ViewTrip = ({
  role,
  trip,
  getTripDataAction,
  acceptTripAction,
  onrouteTripAction,
  declineTripAction,
  completeTripAction,
  pointArrivedAction,
  pointLoadingAction,
  pointOffloadingAction,
  pointCompleteAction

}) => {

  const { id } = useParams();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: context.GOOGLE_API_KEY,
    libraries
  });

  const [confirmationCode, setConfirmationCode] = useState('');
  const [directions, setDirections] = useState(null);
  const [bookings, setBookings] = useState([]);
  const mapRef = useRef();
  const navigate = useNavigate();

  const calculateRoute = (points) => {
    let pointsLatLng = points.map((point) => {
      return {
        lat: point.location.coordinates[1],
        lng: point.location.coordinates[0]
      }
    });

    const origin = pointsLatLng.shift();
    const destination = pointsLatLng.pop();
    let waypoints = [];
    pointsLatLng.forEach((point) => {
      if (point.lat != null) {
        waypoints.push({
          location: point,
          stopover: true
        })
      }
    });

    if (origin.lat != null && destination.lat != null) {
      const directionsService = new window.google.maps.DirectionsService();
      //const origin = { lat: pointsLatLng[0].lat, lng: pointsLatLng[0].lng };
      //const destination = { lat: pointsLatLng[pointsLatLng.length-1].lat, lng: pointsLatLng[pointsLatLng.length-1].lng };

      directionsService.route(
        {
          origin: origin,
          destination: destination,
          waypoints,
          travelMode: window.google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  }

  useEffect(() => {
    getTripDataAction(id);
  }, []);

  useEffect(() => {
    if (trip && isLoaded && trip.status == "onroute") {
      calculateRoute(trip.path);
    }
  }, [trip?.path, isLoaded])

  useEffect(() => {
    if (trip?.status === 'onroute' && trip?.trailer?._id && isLoaded)
      setTimeout(() => {
        TrailerLocationUpdateReceiver.register(trip?.trailer?._id);
      }, 100);
    return () => {
      if (trip?.trailer?._id)
        TrailerLocationUpdateReceiver.unregister(trip?.trailer?._id);
    }
  }, [trip?.trailer?._id, isLoaded, trip?.status])

  useEffect(()=>{
    if (trip?._id) 
      setTimeout(() => {
        TripUpdateReceiver.register(trip?._id);
      }, 100);
    return ()=> {
      if (trip?._id) TripUpdateReceiver.unregister(trip?._id);
    }
  },[trip?._id])

  useEffect(() => {
    if (trip?.path){
      setBookings(getBookingsFromPath(trip.path));
    }
  },[JSON.stringify(trip?.path)])

  const getBookingsFromPath = (path) => {
    const uniqueBookings = [...new Map(path.map(point => [point.booking._id, point.booking])).values()];
    return uniqueBookings
  }

  const getActionButton = () => {
    if (trip.status == "pending") {
      return (
        <button
          className="flex w-full items-center justify-center rounded-md border border-transparent bg-primary-1 px-8 py-3 text-base font-medium text-white hover:bg-neutral-7 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50 hover:no-underline hover:text-white"
          onClick={() => acceptTripAction(id)}
        >
          Accept
        </button>
      );
    }

    if (trip.status == "accepted") {
      return (
        <button
          className="flex w-full items-center justify-center rounded-md border border-transparent bg-primary-1 px-8 py-3 text-base font-medium text-white hover:bg-neutral-7 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50 hover:no-underline hover:text-white"
          onClick={() => onrouteTripAction(id)}
        >
          On Route
        </button>
      );
    }

    if (trip.nextPoint) {
      return (
        <div className="flex flex-col w-full">
          <div>{`address: ${trip.nextPoint.address}`}</div>
          <div>{`point type: ${trip.nextPoint.pointType}`}</div>
          {trip.nextPoint.status == "pending" && (
            <button
              className="flex w-full items-center justify-center rounded-md border border-transparent bg-primary-1 px-8 py-3 text-base font-medium text-white hover:bg-neutral-7 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50 hover:no-underline hover:text-white"
              onClick={() => pointArrivedAction(trip.nextPoint)}
            >
              Arrived
            </button>
          )}
          {trip.nextPoint.status == "arrived" && (
            <button
              className="flex w-full items-center justify-center rounded-md border border-transparent bg-primary-1 px-8 py-3 text-base font-medium text-white hover:bg-neutral-7 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50 hover:no-underline hover:text-white"
              onClick={() => {
                if (trip.nextPoint.pointType == "location") {
                  pointLoadingAction(trip.nextPoint);
                } else {
                  pointOffloadingAction(trip.nextPoint);
                }
              }}
            >
              {trip.nextPoint.pointType == "location"
                ? "Loading"
                : "Offloading"}
            </button>
          )}
          {(trip.nextPoint.status == "loading" ||
            trip.nextPoint.status == "offloading") && (
              <div className="flex flex-col w-full items-center gap-4">
                <OTPInput
                  value={confirmationCode}
                  onChange={setConfirmationCode}
                  numInputs={4}
                  renderSeparator={<span>-</span>}
                  inputStyle={{
                    width: "40px",
                    height: "40px",
                    borderWidth: 2,
                    borderColor: "black",
                    borderRadius: 5
                  }}
                  renderInput={(props) => <input {...props} />}
                />
                <button
                  disabled={confirmationCode.length < 4}
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-primary-1 px-8 py-3 text-base font-medium text-white hover:bg-neutral-7 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50 hover:no-underline hover:text-white"
                  onClick={() => {
                    pointCompleteAction(trip.nextPoint, confirmationCode)
                    setConfirmationCode('');
                  }}
                >
                  {trip.nextPoint.pointType == "location"
                    ? "Picked Up"
                    : "Dropped off"}
                </button>
              </div>
            )}
        </div>
      );
    }

    if (trip.status == "onroute") {
      return (
        <button
          className="flex w-full items-center justify-center rounded-md border border-transparent bg-primary-1 px-8 py-3 text-base font-medium text-white hover:bg-neutral-7 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50 hover:no-underline hover:text-white"
          onClick={() => completeTripAction(id)}
        >
          Complete Trip
        </button>
      );
    }

    if (trip.status == "complete") {
      return (
        <div className="flex w-full items-center justify-center rounded-md border border-transparent bg-primary-1 px-8 py-3 text-base font-medium text-white hover:bg-neutral-7 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50 hover:no-underline hover:text-white">
          {" "}
          Trip Completed
        </div>
      );
    }
  };

  const TripPoint = ({
    point,
    index
  }) =>{
    useEffect(()=>{
      if (point?._d) PointUpdateReceiver.register(point._id);
      return () => {
        if (point?._id) PointUpdateReceiver.unregister(point._id)
      }
    },[point?._id])

    return (
      <tr key={index} className="border-b">
        <td className="p-2">{point.address}</td>
        <td className="p-2">{point.pointType}</td>
        <td className="p-2">
          <span className={`px-2 py-1 rounded-full text-xs font-medium ${COLOR_CODES?.point?.status[point.status]}`}>
            {point.status.toUpperCase()}
          </span>
        </td>
        <td className="p-2">{point.capacity.length}</td>
      </tr>
    );
  }

  if (trip == null || !isLoaded) {
    return (
      <div className="h-screen flex justify-center items-center w-full">
        <div role="status">
          <svg
            aria-hidden="true"
            class="w-28 h-28 mr-2 text-gray-200 animate-spin dark:text-primary-1 fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <div className="mb-6 flex justify-between ">
        <h1 className="text-3xl font-bold text-primary-2">View Trip</h1>
        <nav className="text-sm breadcrumbs">
          <ul>
            <li><Link to="/trips" className="text-green-500 px-4 py-2 border rounded-md hover:border-black hover:text-black hover:no-underline ">Trips</Link></li>
          </ul>
        </nav>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-semibold mb-4">Trip Details</h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center">
              <FaMapMarkedAlt className="text-primary-1 mr-2" />
              <span>{trip.locationAddress ? trip.locationAddress : "Location Address" }</span>
            </div>
            <div className="flex items-center">
              <FaCalendarAlt className="text-primary-1 mr-2" />
              <span>{new Date(trip.date).toLocaleDateString()}</span>
            </div>
            <div className="flex items-center">
              <FaClock className="text-primary-1 mr-2" />
              <span>{trip.timeslot}</span>
            </div>
            <div className="flex items-center">
              <FaUser className="text-primary-1 mr-2" />
              <span>
                {trip.driver
                  ? `${trip?.driver?.firstName} ${trip?.driver?.lastName}`
                  : "Unassigned"}
              </span>
            </div>
            <div className="flex items-center">
              <FaCar className="text-primary-1 mr-2" />
              <span>
                {trip.vehicle
                  ? `${trip?.vehicle?.registrationNumber} - ${trip?.vehicle?.car?.make} ${trip?.vehicle?.car?.model}`
                  : "Unassigned"}
              </span>
            </div>
            <div className="flex items-center">
              <FaTruck className="text-primary-1 mr-2" />
              <span className={`px-2 py-1 rounded-full text-xs font-medium ${COLOR_CODES?.trip?.status[trip.status]}`}>
                {trip.status.toUpperCase()}
              </span>
            </div>
            <div className="flex items-center">
              <FaRulerHorizontal className="text-primary-1 mr-2" />
              <span>{trip.distance || `${Math.round(Math.random() * 100)} km`}</span>
            </div>
            <div className="flex items-center">
              <FaDollarSign className="text-primary-1 mr-2" />
              <span>{trip.fare ? `$${trip.fare}` : `${Math.round(Math.random() * 1000)} USD`}</span>
            </div>
          </div>

          {TripPermissions.isAuthorized("prompts", role) && (
            <div className="mt-6">
              {getActionButton()}
            </div>
          )}
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-semibold mb-4">Trip Points</h3>
          <table className="w-full">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-2 text-left">Address</th>
                <th className="p-2 text-left">Type</th>
                <th className="p-2 text-left">Status</th>
                <th className="p-2 text-left">Quantity</th>
              </tr>
            </thead>
            <tbody>
              {trip.path.map((point, index) => (<TripPoint point={point} index={index}/>))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex flex-row items-center w-full justify-end my-2 gap-4">
        {
          bookings.map((booking, index) => (
            <button
              className='border border-gray-600 rounded-md text-gray-600 bg-white hover:text-white hover:bg-gray-600 w-fit p-2'
              onClick={()=> navigate('/supports/new', { 
                state: {
                  booking,
                  trip
                } 
              })}
            >
              {`New Support Ticket - ${booking.user.firstName} ${booking.user.lastName}`}
            </button>
          ))
        }
        <button
          className='border border-gray-600 rounded-md text-gray-600 bg-white hover:text-white hover:bg-gray-600 w-fit p-2'
          onClick={()=> navigate(`/chats/${trip?.nextPoint?._id}`, { 
            state: {
              booking: trip?.nextPoint,
              trip : trip?._id
            } 
          })}
        >
          Chat
        </button>
      </div>

      {trip?.status === 'onroute' && (
        <div className="mt-6 bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-semibold mb-4">Trip Map</h3>
          <div className="h-96 relative">
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '100%',
                borderRadius: '0.5rem',
              }}
              zoom={15}
              onLoad={(map) => {
                mapRef.current = map;
              }}
              options={{
                styles: MapStyles,
              }}
            >
              {
                directions &&
                <div className="absolute left-3 top-16 bg-white p-4 text-xl text-red-500 font-bold border-gray-300 border-2">
                  {`${(directions.routes[0].legs.reduce((value, leg) => value + leg.distance.value, 0) / 1000).toFixed(2)} km`}
                </div>
              }
              {
                directions &&
                <DirectionsRenderer
                  options={{
                    markerOptions: {
                      icon: {
                        path: 'M17.0710678,2.92893219 C20.9763107,6.83417511 20.9763107,13.1658249 17.0710678,17.0710678 L10,24.1421356 L2.92893219,17.0710678 C-0.976310729,13.1658249 -0.976310729,6.83417511 2.92893219,2.92893219 C6.83417511,-0.976310729 13.1658249,-0.976310729 17.0710678,2.92893219 Z M10,5 C7.23857625,5 5,7.23857625 5,10 C5,12.7614237 7.23857625,15 10,15 C12.7614237,15 15,12.7614237 15,10 C15,7.23857625 12.7614237,5 10,5 Z',
                        fillColor: 'rgb(0,0,255)',
                        fillOpacity: 1,
                        scale: 1.2,
                        strokeColor: 'rgba(255, 255, 255, 0.6)',
                        strokeWeight: 15,
                      },
                      anchorPoint: {
                        x: 0.5,
                        y: 0.5
                      }
                    }
                  }}
                  directions={directions}
                />
              }
              {
                (
                  trip?.trailerLocationCoordinates
                  && trip?.trailerLocationCoordinates[0]
                ) &&
                <MarkerClusterer
                  averageCenter={true}
                  gridSize={2}
                  maxZoom={18}
                >
                  {
                    (clusterer) => {
                      return (
                        <Marker
                          icon={vehicleIcon}
                          clusterer={clusterer}
                          position={{
                            lng: trip?.trailerLocationCoordinates[0],
                            lat: trip?.trailerLocationCoordinates[1]
                          }}
                        />
                      )
                    }
                  }
                </MarkerClusterer>
              }
            </GoogleMap>
            {directions && (
              <div className="absolute left-3 top-3 bg-white p-2 rounded-md shadow-md">
                <span className="font-bold text-primary-1">
                  {`${(directions.routes[0].legs.reduce((value, leg) => value + leg.distance.value, 0) / 1000).toFixed(2)} km`}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default ViewTrip;
