import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import context from '../../../context';
import { PAYMENT_METHODS } from '../../../util/general.util';

const NewPayments = ({
    role,
    userId
}) => {
    const { id } = useParams();
    const [booking, setBooking] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const navigate = useNavigate();

    const getBooking = async () => {
        try {
            let response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/bookings/${id}`);
            setBooking(response.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getBooking();
    }, []);


    const PaymentMethods = ({

    }) => {
        return (
            <>
                <Select
                    className="w-full mt-2 text-black"
                    placeholder="Select Payment Method"
                    options={PAYMENT_METHODS}
                    onChange={(value) => setSelectedPaymentMethod(value)}
                    value={selectedPaymentMethod}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            backgroundColor: 'white',
                            borderColor: 'black',
                            color: 'black',
                        }),
                        menu: (provided) => ({
                            ...provided,
                            backgroundColor: 'white',
                            color: 'black',
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected ? 'black' : 'white',
                            color: state.isSelected ? 'white' : 'black',
                        }),
                    }}
                />

                {
                    ["visa", "mastercard", "zimswitch"].includes(selectedPaymentMethod?.value)&&
                    <PayWithCard
                        booking={booking}
                        sheme={selectedPaymentMethod?.value}
                    />
                }
                {
                    selectedPaymentMethod?.value == 'bank' &&
                    <PayWithBank booking={booking} />
                }
                {
                    ['ecocash', 'onemoney', 'telecash'].includes(selectedPaymentMethod?.value) &&
                    <PayWithMobileMoneyPaynow
                        booking={booking}
                        scheme={selectedPaymentMethod?.value}
                    />
                }
                {
                    selectedPaymentMethod?.value == 'cash' &&
                    <PayWithCash booking={booking} />
                }
                {
                    selectedPaymentMethod?.value == 'wallet' &&
                    <PayWithWallet booking={booking} />
                }
            </>
        )
    }

    const PayWithBank = ({
        booking,
    }) => {
        return (<div>Bank</div>);
    };

    const PayWithMobileMoneyPayfast = () => {
        return (<div>Mobile Money Payfast</div>);
    };

    const PayWithMobileMoneyPaynow = ({
        booking,
        scheme,
    }) => {
        const [selectedContext, setSelectedContext] = useState(null);
        const [ contactNumber, setContactNumber ] = useState(null);
        const [ email, setEmail ] = useState(null);
        const [ selectedCurrency, setSelectedCurrency ] = useState(null);

        const pay = async () => {
            try {
                const response = await axios.post(`${context.SERVER_URL}/api/payments/payWithPaynow`, {
                    id: booking._id,
                    paymentMethod: 'mobile',
                    currency: selectedCurrency?.value,
                    action: "bookingPayment",
                    paymentChannel: scheme,
                    context: selectedContext?.value,
                    paymentNumber: contactNumber,
                    paymentEmail: email
                });

                const payment = response.data;
            } catch (error) {
                console.log(error);
            }
        }

        return (

            <div className="w-full flex flex-col justify-center items-center mt-5">
                {
                    <Select
                        className="w-full mt-2 text-black"
                        placeholder="Select Pay As"
                        options={[
                            {
                                label: 'User',
                                value: 'UserModel',
                            },
                            {
                                label: 'Company',
                                value: 'CompanyModel',
                            },
                        ]}
                        onChange={(value) => setSelectedContext(value)}
                        value={selectedContext}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                backgroundColor: 'white',
                                borderColor: 'black',
                                color: 'black',
                            }),
                            menu: (provided) => ({
                                ...provided,
                                backgroundColor: 'white',
                                color: 'black',
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected ? 'black' : 'white',
                                color: state.isSelected ? 'white' : 'black',
                            }),
                        }}
                    />
                }

                {
                    selectedContext?.value != null &&
                    <Select
                        className="w-full mt-2 text-black"
                        placeholder="Select Currency"
                        options={[
                            {
                                label: 'USD',
                                value: 'USD',
                            },
                            {
                                label: 'ZIG',
                                value: 'ZWL',
                            },
                        ]}
                        onChange={(value) => setSelectedCurrency(value)}
                        value={selectedCurrency}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                backgroundColor: 'white',
                                borderColor: 'black',
                                color: 'black',
                            }),
                            menu: (provided) => ({
                                ...provided,
                                backgroundColor: 'white',
                                color: 'black',
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected ? 'black' : 'white',
                                color: state.isSelected ? 'white' : 'black',
                            }),
                        }}
                    />
                }

                {
                    selectedCurrency?.value != null &&
                    <div className="w-full flex flex-col justify-between items-center">
                        <input 
                            type="text" 
                            placeholder="Mobile Number" 
                            className="w-full p-2 mt-2 text-black"
                            value={contactNumber}
                            onChange={(e) => setContactNumber(e.target.value)}
                        />
                        <input 
                            type="text" 
                            placeholder="Email Address" 
                            className="w-full p-2 mt-2 text-black"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button
                            disabled={contactNumber == null || contactNumber.length < 9 || email == null } 
                            className="bg-black text-white rounded-md mx-3 my-2 px-3 py-2"
                            onClick={() => pay()}
                        >Pay</button>
                    </div>
                }
            </div>
        );
    };

    const PayWithCash = ({
        booking,
    }) => {
        return (<div>Cash</div>);
    };

    const PayWithWallet = ({
        booking,
    }) => {
        const [wallets, setWallets] = useState([]);

        const fetchWallets = useCallback( async () => {
            try {
                const response = await axios.get(`${context.SERVER_URL}/api/payments/walletsForPayment`);
                setWallets(response.data);
            } catch (error) {
                console.log("error fetching wallets", error);
            }
        },[]);

        function classNames(...classes) {
            return classes.filter(Boolean).join(" ");
        }

        const pay = (wallet) => {
            axios.post(`${context.SERVER_URL}/api/payments/payBookingWithWallet`, {
                booking: booking._id,
                wallet: wallet._id,
                currency: wallet.currency,
                amount: booking.fare - booking.paidFare
            }).then((response) => {
                navigate(`/payments/view/${booking._id}`);
            }).catch((error) => {
                console.log(error);
            });
        };

        useEffect(() => {
            fetchWallets();
        }, []);

        return (
            <div className="w-full h-fit flex flex-col justify-center items-center mt-5">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-primary-2 sm:pl-6"
                            >
                                Name
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Owner Type
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Currency
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                            >
                                Tokens
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                            >
                                Available Tokens
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            wallets.map((wallet, index) => {
                                return (
                                    <tr key={wallet._id}>
                                        <td
                                            className={classNames(
                                                index === 0 ? "" : "border-t border-transparent",
                                                "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                                            )}
                                        >
                                            <div className="font-medium text-primary-2">
                                                {
                                                    wallet.ownerType === "UserModel" ? `${wallet.owner?.firstName} ${wallet.owner?.lastName}` : wallet.owner?.name
                                                }
                                            </div>

                                        </td>
                                        <td
                                            className={classNames(
                                                index === 0 ? "" : "border-t border-gray-200",
                                                "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                            )}
                                        >
                                            {wallet.ownerType}
                                        </td>
                                        <td
                                            className={classNames(
                                                index === 0 ? "" : "border-t border-gray-200",
                                                "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                            )}
                                        >
                                            {wallet.currency}
                                        </td>
                                        <td
                                            className={classNames(
                                                index === 0 ? "" : "border-t border-gray-200",
                                                "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                            )}
                                        >
                                            {wallet.tokens}
                                        </td>
                                        <td
                                            className={classNames(
                                                index === 0 ? "" : "border-t border-gray-200",
                                                "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                            )}
                                        >
                                            {wallet.availableTokens}
                                        </td>
                                        <td
                                            className={classNames(
                                                index === 0 ? "" : "border-t border-gray-200",
                                                "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                            )}
                                        >
                                            <button
                                                className="bg-primary-1 text-white rounded-md mx-3 my-2 px-3 py-2"
                                                onClick={() => pay(wallet)}
                                            > Pay </button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>            
        );
    };

    const PayWithCardPayFast = ({
        key,
        booking,
        card,
    }) => {
        return (
            <div className="w-full flex flex-row justify-between items-center mt-5" key={key}>
                <div>{card.cardNickname}</div>
                <form action={process.env.REACT_APP_PAYFAST_URL} method="post" className="flex-1">
                    <input type="hidden" name="merchant_id" value={process.env.REACT_APP_PAYFAST_MERCHANT_ID} />
                    <input type="hidden" name="merchant_key" value={process.env.REACT_APP_PAYFAST_MERCHANT_KEY} />
                    <input type="hidden" name="m_payment_id" value={booking?._id} />
                    <input type="hidden" name="return_url" value={`${window.location.origin}/payments/view/${booking?._id}`} />
                    <input type="hidden" name="cancel_url" value={`${window.location.origin}/payments/new/${booking?._id}`} />
                    <input type="hidden" name="notify_url" value={`${process.env.REACT_APP_SERVER_URL}/api/payments/confirmPayfastPayment`} />
                    <input type="hidden" name="amount" value={(booking.fare - booking.paidFare) > 0 ? (booking.fare - booking.paidFare) : 0} />
                    <input type="hidden" name="item_name" value={'bookingPayment'} />
                    <input type="hidden" name="test" value={true} />
                    <input type="hidden" name="custom_str1" value={userId}/>
                    <input type="hidden" name="custom_str2" value={'card'}/>
                    <input type="hidden" name="custom_str3" value={'vmc'}/>
                    <input type="hidden" name="custom_str4" value={'USD'}/>
                    <input type='hidden' name='custom_str5' value={"UserModel"}/>
                    <input
                        className={`${(booking.paidFare - booking.fare) > 0 ? "bg-gray-300" : "bg-black"} text-white rounded-md py-2 px-3 cursor-pointer hover:bg-gray-800`}
                        disabled={(booking.paidFare - booking.fare) > 0}
                        type="submit"
                    />
                </form>
            </div>
        )
    }

    const PayWithCardPayNow = ({
        key,
        card,
        booking,
    }) => {
    
        const pay = async () => {
            const newWindow = window.open('', '_blank');
            try {
                const response = await axios.post(`${context.SERVER_URL}/api/payments/payWithPaynow`, {
                    id: booking._id,
                    paymentMethod: 'card',
                    currency: 'USD',
                    action: "bookingPayment",
                    paymentChannel: card.scheme,
                    context: card.ownerType,
                });

                const payment = response.data;
                if (newWindow) {
                  newWindow.location.href = payment.redirectUrl;
                }

            } catch (error) {
                newWindow.close();
                console.log(error);
            }
        }

        return (
            <div className="w-full flex flex-col justify-center items-center mt-5" key={key}>
                <div className='w-full flex flex-row justify-between items-center'>
                    <div>{card.cardNickname}</div>
                    <button 
                        className='bg-black text-white rounded-md py-2 px-3 cursor-pointer hover:bg-gray-800' 
                        onClick={() => pay()}
                    >
                        Pay
                    </button>
                </div>
            </div>
        )
    }

    const PayWithCard = ({
        booking,
        scheme
    }) => {
        const [cards, setCards] = useState([]);
        const fetchCards = async () => {
            try {
                const response = await axios.get(`${context.SERVER_URL}/api/cards?schemeFilter=${scheme}`);
                setCards(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        useEffect(() => {
            fetchCards();
        }, []);

        return (
            <div className="w-full flex flex-col justify-center items-center mt-5">
                {
                    cards.map((card, index) => {
                        switch (card.gateway) {
                            case 'payfast':
                                return (
                                    <PayWithCardPayFast
                                        key={index}
                                        card={card}
                                        booking={booking} 
                                    />
                                )
                            case 'paynow':
                                return (
                                    <PayWithCardPayNow
                                        key={index}
                                        card={card}
                                        booking={booking}
                                    />
                                )
                            default:
                                return (<></>)
                        }
                    })
                }
            </div>
        )
    }

    if (booking == null) {
        return (
            <div className="w-full h-full p-5 overflow-y-auto bg-white text-black">
                <div className="w-full flex flex-row justify-between items-center">
                    <div className="text-2xl font-bold">Payment</div>
                </div>
                <div className="w-full flex flex-col justify-center items-center h-64">
                    <div className="text-xl font-bold">Loading...</div>
                </div>
            </div>
        );
    }

    return (
        <div className="w-full h-full p-5 overflow-y-auto bg-white text-black">
            <div className="w-full flex flex-row justify-between items-center">
                <div className="text-2xl font-bold">Payment</div>
                <button
                    className="flex items-center justify-center px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800"
                    onClick={() => navigate(`/bookings/edit/${id}`, {
                        state: {
                            stage: 2,
                        }
                    })}
                >
                    Back
                </button>
            </div>
            <PaymentMethods/>
        </div>
    );
};

export default NewPayments;
